<template>
</template>

<script>

  import UsersService from '@/services/UsersService.js'

  export default {
    name: 'Logout',

    components: {
    },

    data: () => ({

    }),

    mounted () {
      this.logout()
    },

    methods: {
      logout () {
        return UsersService.logout()
        .then(response => {
          localStorage.clear()
          this.$router.push({ name: 'Login' })
        })
        .catch(error => {
          console.log('There was an error:', error.response) // Logs out the error
        })
      },
    },
  }
</script>
